import React from 'react';
import {Link} from 'react-router-dom'

import '../assets/styles/Main.css';

import Video1 from "../assets/videos/portfolio-video.mp4";
import Video2 from "../assets/videos/portfolio-video2.mp4";
import Video3 from "../assets/videos/portfolio-video3.mp4";
import Video4 from "../assets/videos/portfolio-video4.mp4";
import Video5 from "../assets/videos/portfolio-video5.mp4";
import Video6 from "../assets/videos/website-CD-screenrecord_1.mp4";




function Main() {

  return (
    <div className="Main">
        <div class='projects'>
            <a class='links' href="https://www.clubderive.com" target="_blank" rel="noopener noreferrer">
                <div class='link'>
                    <span class="material-icons">
                        link
                    </span>
                </div>
            </a>

            <video class="video1" height="100%" width="100%" loop autoPlay muted playsInline>
                <source src={Video6}></source>
            </video>
            <div className='title fade'>
                <h2>CLUB DERIVE</h2>
                <h3>Website design & development</h3>
                <p>
                    Being part of Club Dérive, a collective with an emphasis on experimental club research and scenography, a participative webdesign had to be realised. Following the playful parameters of Dérive's creative practice that is based upon the theoretical framework of the Situationists, we designed a website together in a series of workshops I organised. Listening to our needs, wishes and desires, we landed on a grande tree of life containing an archive, agenda and pages for publishing articles. Afterwards, I took upon the development and releasing.                    <br></br>
                    <br></br>
                    <br></br>
                    <b>React, Sanity CMS, Three.js ...</b>
                </p>
            </div>
        </div>
        <div class='projects'>
            <a class='links' href="http://sjiek.be/student/2019/lagae_cosima" target="_blank" rel="noopener noreferrer">
                <div class='link'>
                    <span class="material-icons">
                        link
                    </span>
                </div>
            </a>
            <video class="video1" height="100%" width="100%" loop autoPlay muted playsInline>
                <source src={Video2}></source>
            </video>
            <div className='title fade'>
                <h2>PORTFOLIO 1</h2>
                <h3>Website design & development</h3>
                <p>
                    My very first attempt to code. The assignment was to make a portfolio
                    of your work. The viewer can not choose how to go through the website,
                    you need to gide him/her/they through your portfolio.
                    <br></br>
                    <br></br>
                    <br></br>
                    <b>Html, CSS, JS</b>
                </p>
            </div>
        </div>

        <div class='projects'>
            <a class='links' href="https://ehb-mct.github.io/web2-frontend-CosimaLagae/" target="_blank" rel="noopener noreferrer">
                <div class='link'>
                    <span class="material-icons">
                        link
                    </span>
                </div>
            </a>
            <video class="video1" height="100%" width="100%" loop autoPlay muted playsInline>
                <source src={Video4}></source>
            </video>
            <div className='title fade'>
                <h2>NACHTWINKEL</h2>
                <h3>Website design & development</h3>
                <p>
                    Design and Develop a fullstack website where you use a API to retrieve
                    data and display it.
                    I choose to make a website where I displayed all the nightshops from
                    Brussels on a map. I thought how would a website look if a nightshops
                    owner would go to a graphic desinger?
                    <br></br>
                    <br></br>
                    <br></br>
                    <b>Html, CSS, JS, Mapbox, Heroku, MongoDB</b>
                    
                </p>
            </div>
        </div>
        <div class='projects'>
            <a class='links' href="https://scanarchmain.gatsbyjs.io/" target="_blank" rel="noopener noreferrer">        
                <div class='link'>
                    <span class="material-icons">
                        link
                    </span>
                </div>
            </a>
            <video class="video1" height="100%" width="100%" loop autoPlay muted playsInline>
                <source src={Video3}></source>
            </video>
            <div className='title fade'>
                <h2>SCAN.ARCH</h2>
                <h3>Website design & development</h3>
                <p>
                    A simple website to get to know React and Gatsby. I also used Framer
                    Motion to make the website more dynamic.
                    <br></br>
                    <br></br>
                    <br></br>
                    <b>Html, CSS, Gatsby, React, Framer Motion</b>

                    
                </p>
            </div>
        </div>
        <div class='projects'>
            <a class='links' href="https://cosimalagae.github.io/lars-marcoen-portfolio/" target="_blank" rel="noopener noreferrer">
                <div class='link'>
                    <span class="material-icons">
                        link
                    </span>
                </div>
            </a>
            <video class="video1" height="100%" width="100%" loop autoPlay muted playsInline>
                <source src={Video1}></source>
            </video>
            <div className='title fade'>
                <h2>LARS MARCOEN</h2>
                <h3>Website development</h3>
                <p>
                    I had the question to develop a website to use as a porfolio. Lars had
                    already a clear vision about the design, by working together we realised
                    his wishes.
                    <br></br>
                    <br></br>
                    <br></br>
                    <b>Html, CSS, JS</b>
                </p>
            </div>
            
        </div>
        <div class='projects'>
            <a class='links' href="https://werkhuisgenk.be/" target="_blank" rel="noopener noreferrer">       
                <div class='link'>
                    <span class="material-icons">
                        link
                    </span>
                </div>
            </a>
            <video class="video1" height="100%" width="100%" loop autoPlay muted playsInline>
                <source src={Video5}></source>
            </video>
            <div className='title fade'>
                <h2>WERKHUIS</h2>
                <h3>Website design & development</h3>
                <p>
                    As an assignment for myself I developed and designed the website for
                    Werkhuis. A place where students can rent an atelier in Genk. They already
                    had an identity so I had to keep their House Style for the design
                    of the website.
                    <br></br>
                    <br></br>
                    <br></br>
                    <b>Html, CSS, JS, Mapbox</b>
                </p>
            </div>
        </div>
        <div class="extra">
            <Link to='/extras'>
                <h2>SOME LITTLE EXTRAS I MADE.</h2>
            </Link> 
            {/* <a href='https://www.are.na/cosima-rosie-lagae/cool-websites-vip8ph0zw9y' target="_blank" rel="noopener noreferrer">
                <h2>WEBSITES I LIKE.</h2>
            </a> */}
        </div>
        
    </div>
  );
}

export default Main;
