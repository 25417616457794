import React from 'react'
import '../assets/styles/Footer.css';


function Footer() {
  return (
    <div class="footer">
        <h2>COSIMALAGAE@GMAIL.COM  |</h2>
        <a href="https://www.instagram.com/cosima.rosie/" target="_blank" rel="noopener noreferrer">       
            <h2>@COSIMA.ROSIE</h2>
        </a>
    </div>
  )
}

export default Footer
