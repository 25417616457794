import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Outlet } from 'react-router-dom'


import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';

import Main from './pages/Main';
import Extras from './pages/Extras';
import Blender from './pages/Blender';
import Touch from './pages/Touch';


function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route exact path='/' element={<Root />}>
        <Route index path='/' element={<Main />} />
        <Route path="/extras" element={<Extras />} />
        <Route path="/blender" element={<Blender />} />
        <Route path="/touchdesigner" element={<Touch />} />
      </Route>
    )
  )

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

const Root = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default App;
