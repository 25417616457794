import React from 'react'
import { Link } from 'react-router-dom';
import '../assets/styles/Extras.css';

import img1 from '../assets/images/cosimarosie1.png';
import img2 from '../assets/images/touch.png';

function Extras() {
  return (
    <div className='extras' id="link" >
      <Link to='/blender' className="aBlend">
        <div>Blender + Three.js</div>
        <img className="blend" src={img1} alt="" />  
      </Link>
      <Link to='/touchdesigner' className="aTouch">
        <div>Touchdesigner</div>
        <img className="touche" src={img2} alt="" />
      </Link>
    </div>
  )
}

export default Extras
