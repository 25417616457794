import React from 'react'
import '../assets/styles/Touch.css'

import video1 from '../assets/videos/test2-LiquidFlower.mp4';
import video2 from '../assets/videos/test3-InteractiveStructure.mp4';
import video3 from '../assets/videos/test4-MotionTracking.mp4';
import video4 from '../assets/videos/test6-GlowingFlower.mp4';
import video5 from '../assets/videos/test9-mouseLiquidWave.mp4';
import video6 from '../assets/videos/test10-organicColorTunnel.mp4';
import video7 from '../assets/videos/test11-TextureOnInstances.mp4';
import video8 from '../assets/videos/test11-TextureOnInstances2.mp4';
import video9 from '../assets/videos/test12-KinectOrganicColorTunnel.mp4';
import video10 from '../assets/videos/test14-PointCloud.mp4';
import video11 from '../assets/videos/test14-PointCloud1.mp4';

function Touch() {
  return (
    <div className='touch'>
        <video className="video1" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video1}></source>
        </video>
        <video className="video1" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video2}></source>
        </video>
        <video className="video2" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video3}></source>
        </video>
        <video className="video3" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video4}></source>
        </video>
        <video className="video4" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video5}></source>
        </video>
        <video className="video5" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video6}></source>
        </video>
        <video className="video6" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video7}></source>
        </video>
        <video className="video7" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video8}></source>
        </video>
        <video className="video8" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video9}></source>
        </video>
        <video className="video9" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video10}></source>
        </video>
        <video className="video10" height="100%" width="100%" loop autoPlay muted playsInline>
          <source src={video11}></source>
        </video>

    </div>
  )
}

export default Touch